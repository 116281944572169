import styled, { keyframes } from 'styled-components';
import theme from 'utils/theme';

const fadeIn = keyframes`
  
  0% { opacity: 0; }   
  100% { opacity: 1; }
`;

export const BannerTopFadeOut = styled.span`
  animation: 1s ${fadeIn} ease-in;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderAlertLink = styled.a`
  width: 100%;
  color: ${theme.colors.black};
`;
