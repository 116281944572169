import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const defaultValues: Record<string, boolean> = {
  login_button: true,
};

export function useFeatureFlag(key: string): boolean {
  const [state, setState] = useState(
    key in defaultValues ? defaultValues[key] : false,
  );

  const router = useRouter();

  useEffect(() => {
    const featureKey = `feature_${key}`;

    if (featureKey in router.query) {
      if (
        router.query[featureKey] === 'off' ||
        router.query[featureKey] === 'false' ||
        router.query[featureKey] === 'hide' ||
        router.query[featureKey] === '0'
      ) {
        jsCookie.remove(featureKey);
        return setState(false);
      } else {
        jsCookie.set(featureKey, 'enabled', {
          path: '/',
          expires: 365,
        });

        return setState(true);
      }
    }

    if (key in defaultValues) {
      return setState(defaultValues[key]);
    }

    return setState(!!jsCookie.get(featureKey));
  }, [key, router.query]);

  return state;
}
