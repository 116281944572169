import React, { useState, useEffect } from 'react';
import { HeaderAlertProps } from './types';
import styled from 'styled-components';
import theme from 'utils/theme';
import { BannerTopFadeOut, HeaderAlertLink } from './styles';

const Wrapper = styled.div({
  background: theme.colors.lightGreen,
  textAlign: 'center',
  padding: 10,
  fontSize: 14,
  zIndex: theme.zIndexes.modals,
  position: 'relative',
  overflow: 'hidden',
});

function HeaderAlert({ alerts }: HeaderAlertProps) {
  let [alertIndex, setAlertIndex] = useState(0);

  useEffect(() => {
    if (!alerts || alerts.length == 1) {
      return;
    }

    const interval = setInterval(() => {
      setAlertIndex((alertIndex) => {
        let nextIndex = alertIndex + 1;

        alertIndex = nextIndex >= alerts.length ? 0 : nextIndex;
        return alertIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [alerts, setAlertIndex]);

  if (!alerts) {
    return null;
  }

  let alert = alerts[alertIndex];

  return (
    <Wrapper>
      <BannerTopFadeOut>
        <HeaderAlertLink
          href={alert.alertName ? alert.alertName.url : ''}
          target={alert.alertName ? 'blank' : ''}
        >
          {alert?.alertName?.title}
        </HeaderAlertLink>
      </BannerTopFadeOut>
    </Wrapper>
  );
}

export default HeaderAlert;
